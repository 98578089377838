import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import DoctorCard from "../components/doctorCard"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeartPulse } from '@fortawesome/free-solid-svg-icons'
import HowItWorks from "../components/works"
import Enterprise from "../components/enterprise"
import BenefitsDoctor from "../components/benefitsDoctor"
import BenefitsPatient from "../components/benefitsPatient"
import ContactForm from "../components/contactForm"
import Social from "../components/social"
import { Link } from "gatsby"
// import { Script } from 'gatsby'
import SuscriptionsTypesSmall from "../components/suscriptions"


const IndexPage = () => (
  <Layout>
    <Seo title="Kenko plataforma de salud digital" />
    <section className="text-center">
      <div className="row py-lg-5 bg-kenko mx-0">
        <div className='col-1 d-flex justify-content-start'>
        <FontAwesomeIcon icon={faHeartPulse} className='c-white fs-3r opacity-25'/>
        </div>
        <div className="col-10 c-white fs-1 d-flex align-items-center justify-content-center flex-column">
          <StaticImage
            src="../images/kenko-white-large.png"
            width={400}
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="kenko"
            className="img-fluid"
          />
          
          <h1 className="fw-bold c-white">Mi expediente Médico</h1>

          <div >
      <Link to='https://www.kenko.health/' target='_blank' >
        <button className='btn-white border-10 py-2 fs-lh'>Inicia Sesión / Regístrate</button>
      </Link>
    </div>
    
        </div>
        <div className='col-1 d-flex justify-content-end'>
        <FontAwesomeIcon icon={faHeartPulse} className='c-white fs-3r opacity-25 hidden-for-mobile'/>
        </div>
      </div>
      <HowItWorks/>
      
      <BenefitsDoctor/>
      <BenefitsPatient/>
      <Enterprise/>
      <SuscriptionsTypesSmall/>

      {/* <div className=' mx-0 col-12 container-fluid py-5 bg-grayishblue' id='searchPanel' >
        <p className='c-wedgewood fw-bold fs-3 pb-3'>Busca doctores,checa toda su información Y agenda mediante <span className='c-olivine fs-2'> Kenko</span>. </p>
        <div className='grid-4'>
          <DoctorCard/>
        </div>
      </div> */}
      <Social/>
      <section id='contactPanel'>
        <div className='col-12 px-3 px-md-0 pb-3 pt-5'>
          <p className='c-wedgewood fw-bold fs-3'>Contactanos y descubre los beneficios de <span className='c-olivine fs-2'> Kenko</span> como Profesional de la Salud o Paciente </p>
          </div>
        <div className='col-12 col-md-5 mx-auto pb-5'>
          <ContactForm/>
          {/* <Script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/embed/v2.js"></Script>
            <Script id='hubForm' strategy="idle">
              {`hbspt.forms.create({
                region: "na1",
                portalId: "23966114",
                formId: "78d3889c-574f-4771-8dfc-dfe1ac2a7975"
              });`}
            </Script> */}
        </div>
      </section>
     
      {/* <div className="row">
        
        <Link to="/page-2/" className="btn btn-secondary my-2">Go to page 2</Link>
      </div> */}
    </section>
   
  </Layout>
)

export default IndexPage
