import React, { useEffect, useState } from 'react';
import {Col,Container,Input, Collapse,FormGroup,Label } from 'reactstrap';
import { getSuscriptionPlans} from '../services/plans.services';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHospital, faHospitalUser,faUserDoctor, faLaptopMedical, faMedal } from '@fortawesome/free-solid-svg-icons';
import EnterpriseModal from './modals/EnterpriseModal';


const SuscriptionsTypesSmall = () => {
    
    const [plans,setPlans] = useState([])
    const [selectedPlan,setSelectedPlan] = useState('')
    const [active,setActive] = useState(-1)
    const [showData,setShowData] = useState(-1)
    const [isMonthly,setIsMonthly] = useState(true)
    const planCodes = ['0101','0201','0301',]
    const url = 'https://www.kenko.health/authentication/register'
    const dataPricingPlans = [
        { codePlan: '0',
          description:'Acceso a expediente básico del Paciente',
        },
        {
            codePlan: '1',
            description: 'Administración global de expedientes por clinica.',
        },
        {
            codePlan: '2',
            description: 'Administración global de pacientes por clinica.',
        },
        {
            codePlan: '3',
            description: 'Administración de consultorio.',
        },
        {
            codePlan: '4',
            description: 'Expediente medico.',
        },
        {
            codePlan: '5',
            description: 'Gestión de miembros de la clinica.',
        },
        {
            codePlan: '6',
            description: 'Gestión de pagos.',
        },
        {
            codePlan: '7',
            description: 'CIE-11.',
        },
        {
            codePlan: '8',
            description: 'Directorio medico.',
        },
        {
            codePlan: '9',
            description: 'Funciones personalizadas.',
        },
        {
            codePlan: '10',
            description: 'Integracion de procesos.',
        },
        {
            codePlan: '11',
            description: 'Interconectividad via API.',
        },
        {
            codePlan: '12',
            description: 'Link de reervacion de citas',
        },
        {
            codePlan: '13',
            description: 'Manejo de agenda online.',
        },
        {
            codePlan: '14',
            description: 'Multiples consultorios.',
        },
        {
            codePlan: '15',
            description: 'Notificaciones via whatsapp.',
        },
        {
            codePlan: '16',
            description: 'Perfil profesional.',
        },
        {
            codePlan: '17',
            description: 'Prescripcion informada-PLM.',
        },
        {
            codePlan: '18',
            description: 'Receta digital.',
        },
        {
            codePlan: '19',
            description: 'Recordatorio a pacientes.',
        },
        {
            codePlan: '20',
            // description: 'Repositorio de archivos, laboratorios,imagenes,links, y archivos.',
            description: 'Repositorio de archivos',
        },
        {
            codePlan: '21',
            description: 'Solicitud  de laboratorios e imagenes.',
        },
        {
            codePlan: '22',
            description: 'Administración global de agenda.',
        },
    ]

    const types = [
        {
            type: '0001',
            info: ['0','16','8'],
            info2: '',
            iconM: faUserDoctor,
            bgColor: 'bg-cyan'
        },
        {
            type: '0101',
            info: ['16','8','13','4','18','12','20','7','17','3','6','8','21','19'],
            info2: '',
            iconM: faLaptopMedical,
            bgColor: 'bg-mandy'
        },
        {
            type: '0201',
            info2: 'Funciones Starter',
            info: ['14','22','15'],
            iconM: faHospitalUser,
            bgColor: 'bg-asparagus'
        },
        {
            type: '0301',
            info2: 'Funciones Professional',
            info: ['5','2','1'],
            iconM: faHospital,
            bgColor: 'bg-sienna'
        },
        {
            type: '0401',
            info: ['10','11','9'],
            iconM:  faHospitalUser,
            bgColor: 'bg-elm'

        }

    ]

    useEffect(() => {
        onGetSuscriptionPlan()
      },[])

    const onGetSuscriptionPlan = () =>{
      console.log(process.env.GATSBY_API_SERVER,'suscriptions')
        getSuscriptionPlans(null).then(
          (response) => {
            setPlans(response?.data)
          },
          (error) => {
          console.log('Error ', error)
          })
    }

    const toggleCollapse = (index) => {
        if (index == showData){
          setShowData(-1)
        }else{
          setShowData(index) 
        } 
    
      };
    const getPlans = () =>{
        return(
          
            plans?.map((plan,i)=>{
                const planData = types?.find((item) => item?.type == plan?.code);
                // console.log(planData,'plan')
                return(
                <Col name={`card-${plan?.code}`} 
                    className={`card-pricing px-0  text-center py-2 rounded-10  ${active == plan?.code ? 'card-active' : 'bg-white'}`}>
                    {/* {plan?.code == '02' ?
                    <FontAwesomeIcon icon={faMedal} className='fs-2 pull-right c-casablanca mt-4 pt-4' />
                    :''} */}
                   
                    <Col xs='12' className={`ribbon-2 ${planData?.bgColor} mt-1`}>
                        <p className='fs-1r fw-bold mb-0 c-white p-2 '>
                            {plan?.name}
                        {plan?.code == '0201' ? 
                             <FontAwesomeIcon icon={faMedal} className='fs-3 c-white ml-2' />
                        :   ''
                        }
                        </p>
                    </Col>
                    <Col xs='12' className='px-0 mt-3'>
                        <FontAwesomeIcon icon={planData?.iconM} className='fs-1 mx-auto c-mandy py-4' />
                    </Col>
                    <Col xs='12' md='' className={`mt-2 d-flex flex-column justify-content-center text-center px-2 pt-3 c-wedgewood rounded-top ${planCodes.includes(plan?.code) || plan?.code == '0001' ? 'h-190' : '' } `}>
                      
                        { planCodes.includes(plan?.code) ?
                            <div>
                               { !isMonthly ? 
                                     <p className='fw-bold fs-2 mb-0'>
                                      {  Number(plan?.unit_monthly_price) * (1 + Number(plan?.tax))}
                                      <span className='fw-normal fs-6'> {plan?.currency_code} </span>
                                    </p>
                                    :
                                    <>
                                        <p className='fw-bold fs-2 mb-0'> 
                                        { (Number(plan?.unit_yearly_price) * (1 + Number(plan?.tax))).toFixed(0) }
                                        <span className='fw-normal fs-6'> {plan?.currency_code} 
                                         </span>
                                        
                                        </p>
                                        {/* <br/>
                                        <p className='fw-bold fs-3 mb-0'>{new Intl.NumberFormat('es-MX').format(((Number(plan?.unit_yearly_price) * (1 + Number(plan?.tax)))*12).toFixed(0)) }
                                            <span className='fw-normal fs-6'> {plan?.currency_code}
                                            /año
                                            </span>
                                        </p> */}
                                       </>
                                    }
                                <p className='fs-13px mb-0'>Por miembro<br/>
                                </p>
                            </div>
                            : plan?.code == '0001' ?
                                <>
                                    <p className='fw-bold fs-2'>
                                        Gratis
                                    </p>
                                    <br/>
                                    <br/>
                                </>
                            : ''
                        }
                        
                            <p className={`fw-normal mb-0 pb-3  px-2 fs-6  ${planCodes.includes(plan?.code) || plan?.code == '0001' ? 'h-120 mt-2' : 'h-190' }`}>
                                {plan?.description}
                            </p>
                         
                        <Collapse name={plan?.code} isOpen={showData==plan?.code}>
                            <Col xs='12' className='c-sienna fw-bold py-3 pl-0 text-left' >
                                <ul>
                                {planData?.info2 ?
                                    <li className='mb-2'>{planData?.info2}</li>
                                    :''
                                }
                                    {   
                                        dataPricingPlans?.map((dpp)=>(
                                            planData?.info?.includes(dpp?.codePlan) ?
                                            <li className='mb-2'>{dpp?.description}</li>
                                            :''
                                            ))
                                    }
                                </ul>
                            </Col>
                        </Collapse>
                        

                    </Col>
                    
                    <Col xs='12' className='pb-2 mb-2 mt-2'>
                        { showData != plan?.code ?
                            <button className={`bg-none border-none  ${active == plan?.code ? 'c-white' : 'c-mandy'}`}  
                            onClick={()=>{ toggleCollapse(plan?.code)}}>
                                Ver Mas
                            </button>
                        :
                            <button className={`bg-none border-none  ${active == plan?.code ? 'c-white' : 'c-mandy'}`} onClick={()=>toggleCollapse(-1)}>
                                Ver Menos
                            </button>
                        }
                    </Col>

                    <Col xs='12' className='pb-2'>
                        <button className={`btn  px-4 rounded-20 border-none fw-bold  ${active == plan?.code ? 'bg-white c-wedgewood' : 'bg-wedgewood c-white'}`}
                        onClick={()=>{setActive(plan?.code);setSelectedPlan(plan); window.open(`${url}?plan=${plan?.code}`,"_blank")}}>
                            {
                                active == plan?.code ?   
                                'Plan elegido'
                                : 'Elegir Plan'

                            }
                        </button>
                        
                    </Col>
                    
                </Col>
                )
            }
            )
        )
    }
  return (
    <div className="container-fluid bg-grayishblue pb-3 pt-5" id='suscriptionsPanel'>
        <Col xs='12' className='text-center'>
            <p className='c-wedgewood fs-2 fw-bold mb-0'>
                Bienvenido, comienza eligiendo el mejor Plan para tu Practica Clínica
            </p>
            <Col xs='12' className='mt-1 text-center d-flex flex-column justify-content-center'>
                {/* <div className='d-flex justify-content-center'>
                    <p className='c-wedgewood fs-1r mr-2 mb-0'>Necesitas plan empresarial? </p> 
                    <EnterpriseModal/>
                </div> */}
                <p className='c-wedgewood mb-0'>Compromiso</p>
                <div  className='mx-auto d-flex align-items-center c-wedgewood mt-2 mx-auto fw-bold fs-1r cursor-pointer'>
                    Mensual 
                    <FormGroup switch className='ms-2 pl-0'>
                        <Input type="switch" role="switch" className='special-size'
                         checked={isMonthly} 
                         onChange={event=>{setIsMonthly(event.target.checked)}} 
                         id="isMY" name="isMY" 
                         />
                        <Label check className='mt-1 ms-2'>Anual</Label>
                    </FormGroup>
                </div>
            </Col>
        </Col>
        <Col xs='12' className='grid-4-4 mt-2'>
            {getPlans()}
        </Col>
        
    </div>
  );
}

export default SuscriptionsTypesSmall;
