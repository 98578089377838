
import React, { useState } from 'react';
import { Formik,Field,Form } from 'formik';
import * as Yup from 'yup';


const ContactForm = () => {

    const [sended, setSended] = useState(false);
    const typeChecks = ['Paciente','Doctor']
  
    let validation = Yup.object().shape({
    name: Yup.string().required('Nombre es requerido'),
    email: Yup.string().email().required('Correo requerido'),
    description: Yup.string().required('Dejanos un comentario'),

  });

  const createEnterprise = (values) =>{
        console.log(values)
        const _inquiry = {}
        _inquiry.name = values.name
        _inquiry.phone = values.phone_number
        _inquiry.email = values.email
        _inquiry.content = values.comments
        _inquiry.source = 'kenko.mx'
        _inquiry.status = 'inquiry'
        _inquiry.type_id = 1
        _inquiry.role = values.userType ? values.userType == 'Paciente' ? 'patient' : 'doctor' : ''

        console.log(_inquiry)

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({inquiry:_inquiry})
        };
        console.log(requestOptions)

        fetch('https://kenko-mkt-services.herokuapp.com/api/v1/inquiries ',requestOptions)
        .then(
            (response) => {
                const data = response.data
                setSended(true)
                
            }
        )

    
  }

return (
    <div className='px-3 px-md-5 pb-4 pt-5 border bg-white border-20 box-shadow' id='contactPanel'>
        { sended  == false ? (
        <Formik
        initialValues={{
            name: '',
            phone_number: '',
            email: '',
            comments: '',
            userType: ''

        }}
        // validationSchema={validation}
        onSubmit={ async (values) => {
            createEnterprise(values);
            console.log('hola')
        }}
        render = {
            ({
                errors,
                values
            }) => (
            <Form >  
            <div className='row px-3'>
                <div xs='12' md='12' className='mb-3 px-0'>
                    <p className='mb-0 font-weight-bold c-cyan'>Eres:</p>
                    <div className='d-flex d-md-block'>
                    {
                        typeChecks.map((nameLabel,index)=>
                            <label className='c-wedgewood p-2' htmlFor='' >
                            <Field type='radio' name='userType' className='mx-2' value={nameLabel}/>
                            {nameLabel}
                            </label>
                        )}
                    </div>
                </div>
                <div xs='12' md='12' className='mb-3'>
                <p className='mb-0 font-weight-bold c-cyan'>Nombre</p>
                <Field  className={`w-100 c-wedgewood p-2 rounded-10 border-bottom  ${errors.name ? 'border-mandy' : 'border-bottom border-none'}`} name='name' /> 
                
                {errors.company_name ? (
                    <div className='c-mandy'>{errors.name}</div>
                ) : null}
                </div>
                <div xs='12' md='6' className='mb-3'>
                    <p className='mb-0 font-weight-bold c-cyan'>Telefono</p>
                    <Field type='text'
                        className={`w-100 c-wedgewood p-2 rounded-10 ${errors.phone_number ? 'border-mandy' : 'border-bottom border-none'}`}
                        name='phone_number' 
                    />
                    {errors.phone_number ? (
                        <div className='c-mandy'>{errors.phone_number}</div>
                    ) : null}
                </div>
                <div xs='12' md='6' className='mb-3'>
                    <p className='mb-0 font-weight-bold c-cyan'>Email</p>
                    <Field type='email'  className={`w-100 c-wedgewood p-2 rounded-10 ${errors.email ? 'border-mandy' : 'border-bottom border-none'}`} name='email'/>
                    {errors.email ? (
                        <div className='c-mandy'>{errors.email}</div>
                    ) : null}
                </div>
                <div xs='12' md='6' className='mb-3'>
                    <p className='mb-0 font-weight-bold c-cyan'>Comentarios</p>
                    <Field  className={`w-100 c-wedgewood p-2 rounded-10 ${errors.comments ? 'border-mandy' : 'border-bottom border-none'}`} name='comments'
                    setFieldValue=''
                    />
                    {errors.comments ? (
                        <div className='c-mandy'>{errors.comments}</div>
                    ) : null}
                </div>
            <div className='col-12'>
                <button type='submit' className={`border-none py-2 font-weight-bold bg-sienna c-white rounded-20 float-end btn-blue mt-3`}>
                    Guardar
                </button>
            </div>
            </div>
                {/* <button className={`border-none py-2 mx-3 font-weight-bold bg-light-gray c-white rounded-20 btn-orange  mt-3`} >Cancelar</button> */}
            </Form>
        )}/>
        ):(
        <div className='col-12 py-5'>
            <p className='fs-4 c-cyan'>Espera muy pronto noticias nuestras!<br/> Ya haz sido registrado</p>
             <button type='button' onClick={()=>setSended(false)} className={`border-none mx-3 py-2 fw-bold bg-sienna c-white rounded-20 bg-cyan px-4 mt-3`}>
                    Crear otro Registro
                </button>
        </div>
        )
        }
    </div>
    );
}

export default ContactForm
