

import * as React from "react"
import { Link } from "gatsby"
import Seo from "./seo"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHospital,faUserNurse,faHandHoldingMedical,faPeopleArrowsLeftRight, faVideo } from '@fortawesome/free-solid-svg-icons'



const BenefitsDoctor = () => {
    return(
 <section className='container-fluid bg-doctor row mx-0 pt-6 pb-3 ' id='doctorPanel'>
  <div className='col-12 col-md-7 offset-md-5 py-3 text-md-start text-center ps-0'>
      <h1 className='c-white fw-'>
        Los beneficios de usar <span className='c-olivine fw-bold'>MyKenko</span> Doctor
      </h1>
  </div>
  <div className='row col-12'>
    <div className='col-12 col-md-7 offset-md-5 py-4 mb-0 fw-bold'>
      <div className='my-4 col-12 text-start d-flex align-items-center'>
        <FontAwesomeIcon icon={faHospital} className='my-2 fs-4 c-grayish'/> 
        <p className='mb-0 ps-3 fs-5 c-white'>Administración de todos tus consultorios en un solo lugar.</p>
      </div>
      <div className='my-4 col-12 text-start d-flex align-items-center'>
        <FontAwesomeIcon icon={faHandHoldingMedical} className='my-2 fs-4 c-grayish'/> 
        <p className='mb-0 ps-3 fs-5 c-white'>
        Todos tus expedientes al alcance de tu mano en cualquier momento.
          </p>
        </div>
      <div className='my-4 col-12 text-start d-flex align-items-center'>
        <FontAwesomeIcon icon={faVideo} className='my-2 fs-4 c-grayish'/> 
        <p className='mb-0 ps-3 fs-5 c-white'>
        Atiende a más pacientes con la modalidad de video llamada.
          </p>
        </div>
      <div className='my-4 col-12 text-start d-flex align-items-center'>
        <FontAwesomeIcon icon={faUserNurse} className='my-2 fs-4  c-grayish'/> 
        <p className='mb-0 ps-4 fs-5 c-white'>
        Mejora la eficiencia de tus asistentes con su interfaz intuitiva.
          </p>
        </div>
      {/* <div className='my-4'>
        <FontAwesomeIcon icon={faPeopleArrowsLeftRight} className='my-2 fs-4 c-grayish'/>
      Mejora la experiencia de tus pacientes.
        </p>
      </div>  */}
      <div className='my-4 col-12 text-start d-flex align-items-center'>  
        <FontAwesomeIcon icon={faPeopleArrowsLeftRight} className='my-2 fs-4 c-grayish'/> 
        <p className='mb-0 ps-3  fs-5 c-white'>
        Incrementa la comunicación y cercanía con tus pacientes.
        </p>
      </div>
    </div>
    <div className='col-6 text-center offset-5'>
      <Link to='https://www.kenko.health/' target='_blank'>
        <button className='btn-white border-10 py-2'>Regístrate</button>
      </Link>
    </div>
  </div>
</section>
    )
}
export default BenefitsDoctor;
