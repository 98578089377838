
export const requestOptions = (method, data = null, jwt = null) => {

    var request = {
        method: method,
        headers: { "Content-Type": "application/json" },
    }

    if (data != null) {
        request.body = JSON.stringify(data);
		}

    if (jwt != null) {
        request.headers["Authorization"] = `Bearer ${jwt}`;
    }
    return request;
};

export function HandleResponse(response) {
  return response.text().then((text) => {
    // console.log(response);
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if ([401].indexOf(response.status) !== -1) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        // logout();
      }
      // console.log(data);
      // const error = {
      //   status: response.status,
      //   statusText: response.statusText,
      //   code: data?.code,
      //   message: ((data && data.message) || (data && data.errors))
      // }

      const error = ((data && data.message) || (data && data.errors)) || response.statusText;
      return Promise.reject(error);
    }
    // console.log(text);
    // console.log("Respuesta" ,response);
    // data.jwt = response.headers.get('Authorization');
    return Promise.resolve(data);
  });
}

export function HandleResponseExternalApi(response) {
  const {results} = response
  const {lat, lng} = results[0].geometry.location
  const result = { lat: lat, lng: lng }

  if (results.length === 0) {
    return Promise.reject(response.status);
  }
  else {
    return Promise.resolve(result)
  }

  // return response.text().then((text) => {
  //   console.log(response);
  //   const data = text && JSON.parse(text);
  //   if (!response.ok) {
  //     if ([401].indexOf(response.status) !== -1) {
  //       // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
  //       logout();
  //     }

  //     const error = ((data && data.message) || (data && data.errors)) || response.statusText;
  //     return Promise.reject(error);
  //   }

  //   data.jwt = response.headers.get('Authorization');
  //   return Promise.resolve(data);
  // });
}