

import * as React from "react"
import { Link } from "gatsby"
import Seo from "./seo"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF,faInstagram,faLinkedinIn,faYoutube } from "@fortawesome/free-brands-svg-icons"



const Social = () => {
    return(
 <section className='container-fluid row pb-5 pt-6 px-5 mx-0 bg-blue-gradient' id='Social' >
  <div className='col-12 text-center mb-3 pb-5'>
    <h1 className='c-white'>
      Siguenos en nuestras Redes Sociales
    </h1>
  </div>
  <div className='col-12 text-center mt-md-5 justify-content-around d-flex'>
    <Link href='https://www.instagram.com/my_kenko/' target='_blank'><FontAwesomeIcon icon={faInstagram}  className='fs-4x my-2 c-white'/></Link>
    <Link href='https://www.facebook.com/MyKenkoMX' target='_blank'><FontAwesomeIcon icon={faFacebookF}  className='fs-4x my-2 c-white'/></Link>
    <Link href='https://www.linkedin.com/company/kenko-health-tech/' target='_blank'><FontAwesomeIcon icon={faLinkedinIn}  className='fs-4x my-2 c-white'/></Link>
    <Link href='https://www.youtube.com/channel/UCC3kBRlSDnK_QzS_8BfY5Ng' target='_blank'><FontAwesomeIcon icon={faYoutube}  className='fs-4x my-2 c-white'/></Link>
    </div>
   

</section>
    )
}
export default Social;
