

import * as React from "react"
import Seo from "../components/seo"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLaptopMedical,faClipboardUser,faUserDoctor,faCalendarDays } from '@fortawesome/free-solid-svg-icons'



const HowItWorks = () => {
    return(
      <section className='container-fluid row p-5 pt-6 mx-0'>
        <div className='col-12 text-center mb-5'>
          <h2 className='c-wedgewood'>
            Todo en un mismo lugar
          </h2>
        </div>

        <div className='col-md-3 square-over col-12 text-center'>
          <div className='dot bg-zumthor mx-auto'>
            <FontAwesomeIcon icon={faUserDoctor} className='c-elm'/>
          </div>
          <p className='fw-bold mt-2 c-elm'>Doctores</p>
          <p>Encuentra a tu doctor ideal</p>
        </div>
        <div className='col-md-3 square-over col-12 '>
          <div className='dot bg-grayishblue mx-auto'>
              <FontAwesomeIcon icon={faLaptopMedical} className='c-asparagus'/>
          </div>
          <p className='fw-bold mt-2 c-elm'>Consultas Presenciales y Remotas</p>
          <p>Ya no necesitas salir de casa para hablar con un doctor si así lo prefieres</p>
        </div>
        <div className='col-md-3 square-over col-12 text-center'>
          <div className='dot bg-zumthor mx-auto'>
              <FontAwesomeIcon icon={faClipboardUser} className='c-elm' />
          </div>
          <p className='fw-bold mt-2 c-elm'>Expediente médico</p>
          <p>Toda tu información o la de tus pacientes</p>
        </div>
        <div className='col-md-3 square-over col-12 text-center'>
          <div className='dot bg-grayishblue mx-auto'>
              <FontAwesomeIcon icon={faCalendarDays} className='c-asparagus'/>
          </div>
          <p className='fw-bold mt-2 c-elm'>Citas y Agenda</p>
          <p>Maneja tu agenda y consultorios desde cualquier lugar</p>

        </div>
      </section>
    )
}
export default HowItWorks;
