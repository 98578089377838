

import * as React from "react"
import { Link } from "gatsby"
import Seo from "./seo"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandDots,faCommentMedical,faMagnifyingGlass,faShieldHeart,faBookMedical,faHandsHoldingChild } from '@fortawesome/free-solid-svg-icons'


const BenefitsPatient = () => {
    return(
 <section className='container-fluid row pb-5 pt-6 pt-4 px-md-5 px-2 mx-0 bg-grayishblue' id='patientPanel' >
  <div className='col-12 text-center mb-3 pb-md-5'>
    <h1 className='c-wedgewood'>
      Los beneficios de usar <span className='c-olivine fw-bold'>MyKenko</span> Pacientes
    </h1>
  </div>
  <div className='col-md-2 patients-sections col-6 text-center mt-md-5'>
    <div className='dot-square mx-auto'>
    <FontAwesomeIcon icon={faBookMedical} className='my-2 fs-1 c-white'/> 
    </div>
    <p className='c-wedgewood mt-2'>Ten el control de tu historia medica, nunca más vas a perder tu información.</p>
  </div>
  <div className='col-md-2 patients-sections col-6 text-center mt-md-4'>
    <div className='dot-square mx-auto'>
    <FontAwesomeIcon icon={faMagnifyingGlass} className='my-2 fs-1 c-white'/> 
    </div>
    <p className='c-wedgewood mt-2'>Busca y agenda citas con doctores en cualquier parte de la república.</p>
  </div>
  <div className='col-md-2 patients-sections col-6 text-center'>
    <div className='dot-square mx-auto'>
    <FontAwesomeIcon icon={faHandsHoldingChild} className='my-2 fs-1 c-white'/> 
    </div>
    <p className='c-wedgewood mt-2'>Cuida a tus seres queridos, teniendo a la mano su historia medica. </p>
  </div>
  <div className='col-md-2 patients-sections col-6 text-center'>
    <div className='dot-square mx-auto'>
    <FontAwesomeIcon icon={faShieldHeart} className='my-2 fs-1 c-white'/> 
    </div>
    <p className='c-wedgewood mt-2'>Evita la perdida de información, carga y consulta tus estudios y laboratorios.</p>
  </div>
  <div className='col-md-2 patients-sections col-6 text-center mt-md-4'>
    <div className='dot-square mx-auto'>
    <FontAwesomeIcon icon={faCommentMedical} className='my-2 fs-1 c-white'/> 
    </div>
    <p className='c-wedgewood mt-2'>Manten el contacto con todos tus doctores. </p>
  </div>
  <div className='col-md-2 patients-sections col-6 text-center mt-md-5'>
    <div className='dot-square mx-auto'>
    <FontAwesomeIcon icon={faHandDots} className='my-2 fs-1 c-white'/> 
    </div>
    <p className='c-wedgewood mt-2'>Registra tus alergias y todos tus doctores tendrán esta información.</p>
  </div>
  <div className='col-12 text-center'>
    <Link to='https://www.kenko.health/' target='_blank'>
    <button className='btn-blue border-10 py-2'>Registrate</button>
    </Link>
  </div>
</section>
    )
}
export default BenefitsPatient;
