

import * as React from "react"
import { Link } from "gatsby"
import Seo from "./seo"



const Enterprise = () => {
    return(
 <section className='container-fluid row pt-md-3 pt-2 pe-0  pb-4 '>
    <div className='col-12 bg-health d-flex flex-column justify-content-center'>
      <h1 className='col-md-12 col-8 c-wedgewood text-md-start text-center css-br'>
        Integra tus servicios a <span className='fs-1 c-olivine fw-bold'> Kenko </span> <br/>
        y mejora la experiencia de tus pacientes
      </h1>
    <div className='text-center col-8 col-md-9 mt-3'>
      <Link to="/enterprise/" className="btn btn-blue border-10 my-2 p-2">Conoce más</Link>
    </div>
    </div>
 
</section>
    )
}
export default Enterprise;
